var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "company" }, [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("div", [
        _c("div", { staticClass: "wechat-img-style" }, [
          _c("img", { attrs: { src: _vm.jiaoyuyun } }),
          _c("span", { staticClass: "font" }, [_vm._v("数字教育云公众号")])
        ])
      ])
    ]),
    _c("div", { staticClass: "record" }, [
      _c("div", { staticClass: "beian" }, [
        _c("img", { attrs: { src: _vm.beian1 } })
      ]),
      _c(
        "a",
        {
          staticClass: "firend-link",
          attrs: { href: _vm.config.beiAnHao, target: "blank" }
        },
        [_vm._v("豫ICP备16022426号-4")]
      ),
      _c("div", { staticClass: "beian" }, [
        _c("img", { attrs: { src: _vm.beian2 } })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", [_c("div", { staticClass: "guanyu" }, [_vm._v("关于")])]),
      _c("div", { staticClass: "gy-A" }, [
        _c("span", { staticClass: "title" }, [_vm._v("主管单位：")]),
        _c("span", { staticClass: "descr" }, [_vm._v("中原出版传媒集团")])
      ]),
      _c("div", { staticClass: "gy-A" }, [
        _c("span", { staticClass: "title" }, [_vm._v("主办单位：")]),
        _c("span", { staticClass: "descr" }, [_vm._v("河南教育电子音像出版社")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", [_c("div", { staticClass: "guanyu" }, [_vm._v("联系")])]),
      _c("div", { staticClass: "gy-A" }, [
        _c("span", { staticClass: "title" }, [_vm._v("地址：")]),
        _c("span", { staticClass: "descr" }, [
          _vm._v("河南省郑州市河南出版产业园D座5楼")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("div", { staticClass: "wechat-img-style" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }