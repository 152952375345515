<template>
  <div class="footer">
    <div class="company">
      <div class="item">
        <div>
          <div class="guanyu">关于</div>
        </div>
        <div class="gy-A">
          <span class="title">主管单位：</span>
          <span class="descr">中原出版传媒集团</span>
        </div>
        <div class="gy-A">
          <span class="title">主办单位：</span>
          <span class="descr">河南教育电子音像出版社</span>
        </div>
        <!-- <div class="gy-A">
          <span class="title">技术支持：</span>
          <span class="descr">河南悦学网络科技有限公司</span>
        </div> -->
      </div>
      <div class="item">
        <div>
          <div class="guanyu">联系</div>
        </div>
        <!-- <div class="gy-A">
          <span class="title">电话：</span>
          <span class="descr">0371-63952358</span>
        </div>
        <div class="gy-A">
          <span class="title">邮箱：</span>
          <span class="descr">support@hnszjc.com</span>
        </div> -->
        <div class="gy-A">
          <span class="title">地址：</span>
          <span class="descr">河南省郑州市河南出版产业园D座5楼</span>
        </div>
      </div>
      <div>
        <div class="wechat-img-style">
          <!-- <img :src="szjcImg">
          <span class="font">河南省中小学数字教材公众号</span> -->
        </div>
      </div>
      <div>
        <div class="wechat-img-style">
          <img :src="jiaoyuyun">
          <span class="font">数字教育云公众号</span>
        </div>
      </div>
    </div>
    <div class="record">
      <div class="beian"><img :src="beian1"></div>
      <a :href="config.beiAnHao"
        target="blank"
        class="firend-link">豫ICP备16022426号-4</a>
      <div class="beian"><img :src="beian2"></div>
    </div>
  </div>
</template>
<script>
import beian1 from '../../assets/img/beian1.png';
import beian2 from '../../assets/img/beian2.png';
import szjcImg from '../../assets/img/index/szjc.png';
import jiaoyuyun from '../../assets/img/index/jiaoyuyun.jpg';

export default {
  data() {
    return {
      config: window.config,
      beian1,
      beian2,
      szjcImg,
      jiaoyuyun,
    };
  },
};
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 245px;
  background-color: #303035;
  text-align: center;
}
.company {
  width: 1000px;
  margin: auto;
  padding-top: 39px;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  text-align: center;
}
.floatLeft {
  display: inline-block;
  margin: auto;
}
.marginLeft {
  margin-left: 180px;
}
.guanyu {
  font-size: 16px;
  color: #ffffff;
}
.gy-A {
  width: 300px;
  margin-top: 12px;
}
.title {
  font-size: 14px;
  color: #9b9b9b;
}
.item {
  width: 300px;
  text-align: left;
  height: 150px;
}
.descr {
  font-size: 12px;
  color: #9b9b9b;
  margin-top: 3px;
  margin-left: 5px;
}
.firend-link {
  color: #bebebe;
  display: block;
  float: left;
  margin: 0px 5px;
}
a:hover {
  color: #1890ff;
}
.record {
  font-size: 14px;
  color: #bebebe;
  margin: auto;
  width: 230px;
  height: 20px;
  margin-top: -40px;
}
.beian {
  float: left;
}
.wechat-img-style {
  width: 120px;
  height: 206px;
  img {
    margin: auto;
    display: block;
    width: 120px;
    height: 120px;
  }
  span {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(89, 89, 89, 1);
    line-height: 17px;
    text-align: center;
    margin-top: 15px;
    display: block;
  }
  .font {
    color: #9b9b9b;
    font-weight: 400;
    font-size: 12px;
  }
}
</style>
